import { isFeatureEnabled } from 'ssotool-app/shared/services/feature-flagger/feature-flagger.util';
import { FeatureFlag } from 'ssotool-app/shared/services/feature-flagger/feature-flags.config';

import { NgModule } from '@angular/core';
import { Route, RouterModule, Routes } from '@angular/router';

import { ArchetypeListComponent } from './containers/archetype-list';
import { CompanyListComponent } from './containers/company-list';
import { ExistingContractsListComponent } from './containers/existing-contracts-list';
import { ExistingConverterListComponent } from './containers/existing-converter-list';
import { ExistingRenewableListComponent } from './containers/existing-renewable-list';
import { ExistingStorageListComponent } from './containers/existing-storage-list';
import { FlowPricesListComponent } from './containers/flow-prices-list';
import { FlowVolumesListComponent } from './containers/flow-volumes-list';
import { FluidListComponent } from './containers/fluid-list';
import { GeographyListComponent } from './containers/geography-list';
import { MetricsListComponent } from './containers/metrics-list';
import { ProcessListComponent } from './containers/process-list';
import { ScalingFactorListComponent } from './containers/scaling-factor-list';
import { SectorListComponent } from './containers/sector-list';
import { SitesListComponent } from './containers/sites-list';
import { TimeSeriesListComponent } from './containers/timeseries-list';
import { TrajectoriesListComponent } from './containers/trajectories-list';

export interface EntityRouteReference {
  readonly value: string;
  readonly label: string;
  readonly component: any;
  readonly id?: string;
}

export const MAIN_ENTITIES: EntityRouteReference[] = [
  {
    value: 'companies',
    label: 'Company Entities',
    component: CompanyListComponent,
    id: 'companyEntityId',
  },
  {
    value: 'geographies',
    label: 'Geographies',
    component: GeographyListComponent,
    id: 'geoId',
  },
  ...(isFeatureEnabled(FeatureFlag.INPUT_SIMPLIFICATION_FEATURE)
    ? [
        {
          value: 'archetypes',
          label: 'Archetypes',
          component: ArchetypeListComponent,
          id: 'archetypeId',
        },
        {
          value: 'sites',
          label: 'Sites',
          component: SitesListComponent,
          id: 'siteId',
        },
      ]
    : [
        {
          value: 'sectors',
          label: 'Sectors',
          component: SectorListComponent,
          id: 'sectorId',
        },
      ]),
  {
    value: 'fluids',
    label: 'Fluids',
    component: FluidListComponent,
    id: 'fluidId',
  },
  {
    value: 'processes',
    label: isFeatureEnabled(FeatureFlag.INPUT_SIMPLIFICATION_FEATURE_R2)
      ? 'Energy Processes'
      : 'Processes',
    component: ProcessListComponent,
    id: 'processId',
  },
  ...(isFeatureEnabled(FeatureFlag.INPUT_SIMPLIFICATION_FEATURE)
    ? [
        {
          value: 'scalingFactors',
          label: 'Scaling Factors',
          component: ScalingFactorListComponent,
          id: 'scalingFactorId',
        },
      ]
    : []),
];

export const EXISTING_ENTITIES: EntityRouteReference[] = [
  {
    value: 'existingConverters',
    label: 'Existing Converters',
    component: ExistingConverterListComponent,
  },
  {
    value: 'existingRenewables',
    label: 'Existing Renewables',
    component: ExistingRenewableListComponent,
  },
  {
    value: 'existingContracts',
    label: 'Existing Contracts',
    component: ExistingContractsListComponent,
  },
  ...(isFeatureEnabled(FeatureFlag.INPUT_SIMPLIFICATION_FEATURE)
    ? []
    : [
        {
          value: 'existingStorages',
          label: 'Existing Storages',
          component: ExistingStorageListComponent,
        },
      ]),
];

export const SECONDARY_ENTITIES: EntityRouteReference[] = [
  ...(isFeatureEnabled(FeatureFlag.INPUT_SIMPLIFICATION_FEATURE)
    ? []
    : [
        {
          value: 'metrics',
          component: MetricsListComponent,
          label: 'Metrics',
        },
      ]),
  {
    value: 'trajectories',
    component: TrajectoriesListComponent,
    label: 'Trajectories',
  },
  {
    value: 'timeSeries',
    component: TimeSeriesListComponent,
    label: 'Time Series',
  },
  {
    value: 'flowsVolumes',
    component: FlowVolumesListComponent,
    label: 'Flows - Volumes',
  },
  {
    value: 'flowsPrices',
    component: FlowPricesListComponent,
    label: 'Flows - Prices',
  },
];

const secondaryRoutes: Routes = SECONDARY_ENTITIES.map<Route>((entity) => ({
  path: entity.value,
  component: entity.component,
  data: {
    breadcrumb: entity.label,
    reference: false,
  },
})).concat({
  path: '',
  redirectTo: SECONDARY_ENTITIES[0].value,
  pathMatch: 'full',
});

const mainRoutes: Routes = MAIN_ENTITIES.map<Route>((entity) => ({
  path: entity.value,
  children: [
    {
      path: `:${entity.id}`,
      children: secondaryRoutes,
      data: {
        breadcrumb: entity.label,
        type: entity.value,
        referenceKey: entity.id,
        reference: true,
        secondary: true,
      },
    },
    {
      path: '',
      component: entity.component,
    },
  ],
  data: {
    breadcrumb: entity.label,
    type: entity.value,
    reference: false,
  },
}));

const existingRoutes: Routes = EXISTING_ENTITIES.map<Route>((entity) => ({
  path: entity.value,
  component: entity.component,
  data: {
    breadcrumb: entity.label,
    type: entity.value,
  },
}));

// export const DATA_IMPORT_ROUTE = {
//   path: 'import',
//   component: DataImportComponent,
//   data: {
//     breadcrumb: 'Data Import',
//     type: 'dataImport',
//   },
//   resolve: [DataImportResolverService],
// };

const routes: Routes = mainRoutes.concat(existingRoutes).concat(
  {
    path: '',
    redirectTo: MAIN_ENTITIES[0].value,
    pathMatch: 'full',
  },
  // DATA_IMPORT_ROUTE,
);

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EntitiesRoutingModule {}
